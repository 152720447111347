define("ev-join/utils/fetch", ["exports", "fetch", "ev-join/config/environment", "@sentry/browser"], function (_exports, _fetch, _environment, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchApi;
  _exports.checkStatus = checkStatus;
  _exports.parseJSON = parseJSON;
  _exports.reportSentry = reportSentry;
  var HOST = _environment.default.APP.EV_API_HOST;

  function fetchApi(url) {
    for (var _len = arguments.length, otherArgs = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      otherArgs[_key - 1] = arguments[_key];
    }

    return _fetch.default.apply(void 0, ["".concat(HOST, "/").concat(_environment.default.APP.EV_API_NAMESPACE).concat(url ? "/".concat(url) : '')].concat(otherArgs));
  }

  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  function parseJSON(response) {
    return response.json();
  }

  function reportSentry(callback) {
    return function (error) {
      if (error.response) {
        error.response.json().then(function (json) {
          Sentry.captureException(error, {
            extra: {
              response: error.response,
              jsonresponse: json
            }
          });
        }).catch(function () {
          Sentry.captureException(error, {
            extra: {
              response: error.response
            }
          });
        });
      }

      if (callback) {
        return callback(error);
      }

      return error;
    };
  }
});