define("ev-join/components/required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="text-danger">*</span>
  */
  {"id":"kJ2LgG76","block":"{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"ev-join/components/required.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});