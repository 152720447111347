define("ev-join/markdown/heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = headingPlugin;

  function isSpace(code) {
    switch (code) {
      case 0x09:
      case 0x20:
        return true;
    }

    return false;
  }

  function headingPlugin(md, options) {
    function heading(state, startLine, endLine, silent) {
      var ch,
          level,
          tmp,
          token,
          pos = state.bMarks[startLine] + state.tShift[startLine],
          max = state.eMarks[startLine]; // if it's indented more than 3 spaces, it should be a code block

      if (state.sCount[startLine] - state.blkIndent >= 4) {
        return false;
      }

      ch = state.src.charCodeAt(pos);

      if (ch !== 0x23
      /* # */
      || pos >= max) {
        return false;
      } // count heading level


      level = 1;

      if (options.hasOwnProperty('shift')) {
        level += options.shift;
      }

      ch = state.src.charCodeAt(++pos);

      while (ch === 0x23
      /* # */
      && pos < max && level <= 6) {
        level++;
        ch = state.src.charCodeAt(++pos);
      }

      if (level > 6 || pos < max && !isSpace(ch)) {
        return false;
      }

      if (silent) {
        return true;
      } // Let's cut tails like '    ###  ' from the end of string


      max = state.skipSpacesBack(max, pos);
      tmp = state.skipCharsBack(max, 0x23, pos); // #

      if (tmp > pos && isSpace(state.src.charCodeAt(tmp - 1))) {
        max = tmp;
      }

      state.line = startLine + 1;
      token = state.push('heading_open', 'h' + String(level), 1);
      token.markup = '########'.slice(0, level);
      token.map = [startLine, state.line];
      token = state.push('inline', '', 0);
      token.content = state.src.slice(pos, max).trim();
      token.map = [startLine, state.line];
      token.children = [];
      token = state.push('heading_close', 'h' + String(level), -1);
      token.markup = '########'.slice(0, level);
      return true;
    }

    md.block.ruler.at('heading', heading);
  }
});