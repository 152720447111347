define("ev-join/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WgvzvUzf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-fluid page loading vh-100\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row h-100\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-3 page-left\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/images/icon_white.png\"],[14,\"alt\",\"Erasmus Volley\"],[12],[13],[2,\"\\n            \"],[10,\"h3\"],[12],[2,\"Erasmus Volley\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-9 page-right\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"page-form mx-auto mb-4\"],[12],[2,\"\\n                \"],[10,\"h2\"],[14,0,\"loading-title\"],[12],[2,\"Loading...\"],[13],[2,\"\\n                \"],[10,\"p\"],[14,0,\"loading-message\"],[12],[1,[32,0,[\"loadingText\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ev-join/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});