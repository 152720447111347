define("ev-join/components/form/input-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @helpText}}
      <div class="input-group-desc">
          {{yield}}
          <label>{{@helpText}}</label>
      </div>
  {{else}}
      {{yield}}
  {{/if}}
  
  */
  {"id":"FAEfidpY","block":"{\"symbols\":[\"&default\",\"@helpText\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"input-group-desc\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n        \"],[10,\"label\"],[12],[1,[32,2]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"ev-join/components/form/input-group.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});